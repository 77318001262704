window.wireSelect2ToLivewire = function(el, callback, updateEvent, placeholder, searchable = false) {

    document.addEventListener("livewire:load", () => {
        initSelect()

        Livewire.hook('message.processed', (message, component) => {
            initSelect()
        })

        Livewire.on(updateEvent, values => {
            el.val(values).trigger('change.select2')
        })

        el.on('change', callback)

        function initSelect () {
            el.select2({
                placeholder: placeholder,
                minimumResultsForSearch: searchable ? 10 : Infinity,
                allowClear: false,
                // allowClear: searchable,
            })
        }
    })
}

<template>
  <div>
    <div class="fake-embed" id="startMeetingButtonContainer">
      <button class="btn btn-primary" id="startMeetingButton" @click.prevent="getSignature">Partecipa nella chiamata di Teleconsulto</button>
    </div>

    <!-- For Component View -->
    <div id="meetingSDKElement">
      <!-- Zoom Meeting SDK Component View Rendered Here -->
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'VideoCallAlternative',
  created () {
  },
  props: [
    'apiKey',
    'leaveUrl',
    'meetingNumber',
    'passWord',
    'signatureEndpoint',
    'userEmail',
    'userName',
  ],
  data () {
    return {
      client: this.ZoomMtgEmbedded.createClient(),
      // apiKey: "DVfdXHbUTyqjv3uWNIgGXg",
      // leaveUrl: "http://localhost:8080",
      // meetingNumber: "96917249378",
      // passWord: "F3Q4LF",
      role: 0,
      // signatureEndpoint: "https://omnyacare.test/zoom-signature",
      // userEmail: "tony@stark.com",
      // userName: "Tony Stark",
      // apiKey: "",
      // meetingNumber: "123456789",
      // passWord: "",
      // role: 0,
      // signatureEndpoint: "",
      // userEmail: "",
      // userName: "Vue.js",
    }
  },
  methods: {
    getSignature() {
      axios.post(this.signatureEndpoint, {
        meetingNumber: this.meetingNumber,
        role: this.role
      })
      .then(res => {
        // console.log(res.data);
        this.startMeeting(res.data);
        document.getElementById('startMeetingButtonContainer').style.display = 'none';
        document.getElementById('meetingSDKElement').style.display = 'block';
      })
      .catch(error => {
        console.log(error);
      });
    },
    startMeeting(signature) {
      let meetingSDKElement = document.getElementById('meetingSDKElement');

      // setTimeout(() => document.querySelector('.react-draggable').classList.toggle('react-draggable'), 100)

      this.client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: 'it-IT',
        customize: {
          meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
          toolbar: {
            buttons: [
              {
                text: 'Custom Button',
                className: 'CustomButton',
                onClick: () => {
                  console.log('custom button');
                }
              }
            ]
          }
        }
      });

      this.client.join({
        apiKey: this.apiKey,
        signature: signature,
        meetingNumber: this.meetingNumber,
        password: this.passWord,
        userName: this.userName,
        userEmail: this.userEmail
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #zmmtg-root {
      position: initial;
      display:  none;
      background-color: red;
  }

   #startMeetingButtonContainer {
    display:  flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    height: 67vh;
   }

  #meetingSDKElement {
    display:  none;
    position: relative!important;
    /*background-color:  red!important;*/
    top: auto!important;
    left: auto!important;
    right: auto!important;
    height: 80vh!important;
    width:  auto!important;
  }

  #meetingSDKElement > div {
    border-radius:  none!important;
  }

  .react-draggable {
    width:  100%!important;
  }

  .react-draggable, .react-draggable-dragged {
    transform: none!important;
  }

  .MuiToolbar-root {
    display:  none!important;
  }

  .full-view1 {
    height:  654px!important;
    width:  100%!important;
  }

  .jss10, .jss29, .jss35 {
    width:  100%!important;
  }

  .jss7 {
    height:  67vh!important;
    width:  100%!important;
    border-radius: 0!important;
  }

  .MuiPaper-elevation1, .jss2 {
    box-shadow: none!important;
    }
  .MuiPaper-rounded, .jss114 {
    border-radius: 0!important;
  }

  .jss114 {
    width: 100%!important;
    height: 100%!important;
    top:  0!important;
    left:  0!important;
  }

  #transition-popper {
    z-index: 1!important;
  }

  [title="Condividi schermo"] {
    display:  none!important;
  }

</style>

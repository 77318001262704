/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('select2');
require('sticky-sidebar/dist/jquery.sticky-sidebar.min.js');
require('air-datepicker/dist/js/datepicker.min.js');
require('./includes/datepicker.it.js');
require('./includes/jquery.sticky.min.js');
require('./includes/ResizeSensor.js');
// require('./includes/jquery.range.min.js');
import LazyLoad from "vanilla-lazyload";
import Swiper from 'swiper';
import { Dropzone } from 'dropzone';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import itLocale from '@fullcalendar/core/locales/it';

require('./select2-livewire-bridge')

window.Vue = require('vue').default;
window.moment = require('moment');
window.Dropzone = Dropzone;
window.LazyLoad = LazyLoad;
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.itLocale = itLocale;

import 'livewire-vue'

Vue.prototype.ZoomMtg = window.ZoomMtg;
Vue.prototype.ZoomMtgEmbedded = window.ZoomMtgEmbedded;

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Vue.component('video-call', require('./components/VideoCall.vue').default);
Vue.component('video-call-alt', require('./components/VideoCallAlternative.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

$(document).ready(function() {

    var windowWidth = $(window).width()

    // ----------------------------------------
    // Scroll event
    // ----------------------------------------
    $(window).scroll(function() {
        var scroll = $(window).scrollTop()
        if (scroll >= 10) {
            $('.header-wrapper').addClass('scrolled')
        } else {
            $('.header-wrapper').removeClass('scrolled')
        }
    });

    // ----------------------------------------
    // Hamburger menu
    // ----------------------------------------
    $('.hamburger-menu').on('click', function() {
        $(this).toggleClass('opened')
        $('header').toggleClass('opened')
        $('#mobile-menu').toggleClass('opened')
    })

    // ----------------------------------------
    // Toggle Show/Hide Password input
    // ----------------------------------------
    if($('.form-group .view--password').length) {
        $('.form-group .view--password').on('click', function() {
            $(this).toggleClass('showing')
            var inputPassword = $(this).parent().find('input')
            if($('.view--password').hasClass('showing')) {
                inputPassword.attr('type','text')
            } else {
                inputPassword.attr('type','password')
            }
        })
    }

    $('[data-toggle="tooltip"]').tooltip()

    // ----------------------------------------
    // Toggle Max height
    // ----------------------------------------
    if($('.section--content-find-service h4.title-5').length) {
        $('.section--content-find-service h4.title-5').on('click', function() {
            $(this).parent().parent().parent().parent().toggleClass('opened')
        })
    }
    if($('.section--content-search h5.title-5').length) {
        $('.section--content-search h5.title-5').on('click', function() {
            $(this).parent().parent().parent().parent().toggleClass('opened')
        })
    }
    if($('.single--result').length) {
        $('.single--result .fa-angle-down').on('click', function() {
            $(this).parent().toggleClass('opened')
        })
    }

    // ----------------------------------------
    // View all Health data
    // ----------------------------------------
    if($('.view-all-data').length) {
        $('.view-all-data').on('click', function() {
            $(this).parent().addClass('hide')
            $('.block-health-data-min-view').addClass('view-all')
        })
    }

    // ----------------------------------------
    // Count table item
    // ----------------------------------------
    if($('.list--table').length) {
        if(windowWidth >= 992) {
            if($('.list--table-wrapper .list--table-item').length > 9) {
                console.log($('.list--table-wrapper .list--table-item').length)
                $('.list--table').addClass('scrollable')
            }
        } else {
            if($('.list--table-wrapper .list--table-item').length > 5) {
                $('.list--table').addClass('scrollable')
            }
        }
    }

    // ----------------------------------------
    // Add attribute checked
    // ----------------------------------------
    $('.form-check:not(.form-check-switch) label').on('click', function() {
        $(this).parent().toggleClass('checked');
        var input = $(this).parent().find('input');
        // if(input.attr('checked')) {
        //     input.removeAttr('checked');
        // } else {
        //     input.attr('checked', 'checked');
        // }
    });
    $('.form-check-radio label').on('click', function() {
        $(this).parent().addClass('checked');
        var input = $(this).parent().find('input');
        $(this).parent().siblings().removeClass('checked');
        $(this).parent().siblings().find('input').removeAttr('checked');
        input.attr('checked', 'checked');
    });
    $('.payment--method').on('click', function() {
        var checkId = $(this).data('check-id');
        var parent = $(this).parent();
        parent.addClass('checked');
        var input = parent.find('input');
        parent.siblings().removeClass('checked');
        parent.siblings().find('input').removeAttr('checked');
        input.attr('checked', 'checked');
    });

    var typeRequest = $('[name="type-request"]');
    typeRequest.change(function() {
        var value = this.value;
        console.log(value)
        if (value == 'company-laboratory') {
            $('#description-input').removeClass('hide');
            $('#doctor-specialization-input').addClass('hide');
        }
        if (value == 'private-doctor') {
            $('#doctor-specialization-input').removeClass('hide');
            $('#description-input').addClass('hide');
        }
    });

    // ----------------------------------------
    // Modal
    // ----------------------------------------
    $('.modal [data-dismiss=modal]').on('click', function(){
        var idModal = $(this).data('target-modal');
        $(idModal).modal('show');
        if (typeof idModal !== typeof undefined && idModal !== false) {
            setTimeout(function() {
                $('body').addClass('modal-open');
            }, 350)
        }
    });

    $('#newTimeModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#newTimeModalForm').attr('action', formPostUrl);
    });

    $('#newTimeModalSubmit').on('click', function(e) {
        e.preventDefault();
        var newScheduleDate = $('#new_schedule_date').val();
        var newScheduleTime = $('#new_schedule_time').val();
        var newSchedule = moment(newScheduleDate + " " + newScheduleTime, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm');
        $('#new_schedule_at').val(newSchedule);
        $('#newTimeModalForm').submit();
    });

    $('#removeReservationPendingModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#removeReservationPendingForm').attr('action', formPostUrl);
    });

    $('#notificationRefuseAppointmentAfterTimeChangedProposedModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#notificationRefuseAppointmentAfterTimeChangedProposedRetireForm').attr('action', formPostUrl);
    });

    $('#notificationAcceptAppointmentAfterTimeChangedProposedModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#notificationAcceptAppointmentAfterTimeChangedProposedRetireForm').attr('action', formPostUrl);
    });

    $('#confirmAcceptAppointmentModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#confirmAcceptAppointmentForm').attr('action', formPostUrl);
    });

    $('#accessRequestModal').on('show.bs.modal', function(e) {
        var formPostUrl = $(e.relatedTarget).attr('data-formposturl') ?? '';
        $(this).find('#accessRequestModalForm').attr('action', formPostUrl);
    });
    
    $('#unavailabilitySlotsModal').on('show.bs.modal', function(e) {
        var siteId = $(e.relatedTarget).attr('data-siteid') ?? '';
        var slotId = $(e.relatedTarget).attr('data-slotid') ?? '';
        Livewire.emit('initUnavailabilitySlotsModal', siteId, slotId);
    });

    $('#notificationDeleteUnavailabilitySlotModal').on('show.bs.modal', function(e) {
        var slotId = $(e.relatedTarget).attr('data-slotid') ?? '';
        Livewire.emit('initUnavailabilitySlotDeleteNotify', slotId);
    });

    function switchTabs(idTab, clickedElm = null) {
        console.log(clickedElm);
        if (clickedElm) {
            clickedElm.parent().addClass('current-item')
            clickedElm.parent().siblings().removeClass('current-item')
        } else {
            var clickedElm = ($("span[data-tab-id='" + idTab + "']"));
            clickedElm.parent().addClass('current-item')
            clickedElm.parent().siblings().removeClass('current-item')
        }
        var currentTab = $(document).find("[data-id='" + idTab + "']")
        currentTab.removeClass('hide')
        currentTab.addClass('show')
        if(currentTab.siblings().hasClass('show')) {
            currentTab.siblings().removeClass('show')
            currentTab.siblings().addClass('hide')
        }
    }
    // ----------------------------------------
    // Folder Tabs
    // ----------------------------------------
    $('#reserved-area-tabs span').on('click', function() {
        var idTab = $(this).data('tab-id')
        switchTabs(idTab, $(this));
    });

    // ----------------------------------------
    // Detail Tabs
    // ----------------------------------------
    $('#detail-tabs span').on('click', function() {
        var idTab = $(this).data('tab-id')
        switchTabs(idTab, $(this));
    });

    if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        switchTabs(hash);
    }

    // ----------------------------------------
    // Air Datepicker
    // ----------------------------------------
    if(jQuery('.datepicker-here').length) {
        $('.datepicker-here').datepicker({
            language: 'it',
            toggleSelected: true,
            autoClose: true,
            clearButton: true,
            dateFormat: 'dd/mm/yyyy',
            // minDate: new Date(),
            // maxDate: new Date(),
        })
    }

    if(jQuery('#payment-method-expiry').length) {
        $('#payment-method-expiry').datepicker({
            language: 'it',
            toggleSelected: true,
            autoClose: true,
            clearButton: true,
            dateFormat: 'mm/yy',
            // minDate: new Date(),
            // maxDate: new Date(),
        })
    }

    // ----------------------------------------
    // Init Lazy Load
    // ----------------------------------------
    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
        load_delay: 100 //adjust according to use case
    });

    // ----------------------------------------
    // Init Select2
    // ----------------------------------------
    $("select:not(.no-select2)").each(function() {
        $(this).select2({
            minimumResultsForSearch: $(this).hasClass('searchable') ? 10 : Infinity,
            placeholder: $(this).attr('placeholder')
        });
    });
    $("select[multiple]:not(.no-select2)").each(function() {
        $(this).select2({
            placeholder: $(this).attr('placeholder')
        });
    });
    $("select[name='sharing-timing']").each(function() {
        $(this).select2({
            minimumResultsForSearch: Infinity,
            placeholder: $(this).attr('placeholder'),
            dropdownCssClass: 'sharing-timing',
            selectionCssClass: 'sharing-timing'
       });
    });

    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
      });

    // ----------------------------------------
    // Init Swiper
    // ----------------------------------------
    const customerLogos = new Swiper('#customer--logos', {
        loop: true,
        breakpoints: {
            320: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 30
            },
            992: {
              slidesPerView: 7,
              spaceBetween: 40
            },
            1200: {
              slidesPerView: 9,
              spaceBetween: 40
            }
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
    });

    // ----------------------------------------
    // Sticky Elements
    // ----------------------------------------
    window.stickResultBars = function () {
        // console.log('stuck')
        $("#sticky-service-search").unstick();
        $("#sticky-service-categories").unstick();
        if(windowWidth <= 992) {
            $("#sticky-service-search").sticky({
                topSpacing: 60
            });
            $("#sticky-service-categories").sticky({
                topSpacing: 117
            });
        }
        if(windowWidth >= 992) {
            $("#sticky-service-search").sticky({
                topSpacing: 90
            });
            $("#sticky-service-categories").sticky({
                topSpacing: 170
            });
            $(".section--content-filter-results").each(function() {
                var filterId = $(this).attr('id');
                var sidebar = new StickySidebar('#' + filterId, {
                    topSpacing: 222,
                    resizeSensor: true,
                    containerSelector: '.section--content-results',
                    innerWrapperSelector: '.section--content-filter-results',
                });
            });
        }
    }

    // window.stickResultBars()

    // ----------------------------------------
    // Response
    // ----------------------------------------
    $(".response").click(function() {
        $(this).toggleClass('active')
    });

    // ----------------------------------------
    // Read more
    // ----------------------------------------
    $(document).on("click", ".read-more", function() {
        console.log('clicked');
        var toggle = $(this).data("toggle-length");
        console.log(toggle);
        $(this).parent().parent().hide();
        console.log($(this).parent().parent());
        $(`#text-${toggle}`).show();
    });

    // ----------------------------------------
    // Copy clipboard
    // ----------------------------------------
    $('#copy-referral-trigger').on('click', function(e) {
            e.preventDefault()
          var copyText = document.getElementById("copy-referral-link");

          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */
          navigator.clipboard.writeText(copyText.value);
    })

    // ----------------------------------------
    // Kebab icon
    // ----------------------------------------
    $('.kebab-icon').on('click', function(e) {
        e.preventDefault()
        var optionsTable = $(this).parent().parent().parent().parent().find('#options-table');
        optionsTable.toggleClass('hidden');
        e.stopPropagation()
    })

});
